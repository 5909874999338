import styles from "./styles.module.scss"
import Button from "../../button"
import ArrowRight from "../../../icons/arrow-right"
import ArrowsDown from "../../../icons/arrows-down"

export default function InternalVacancies({ title, items }) {
    return (
        <div className={styles.wrapper}>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.grid}>
                {items.map((item) => (
                    <div key={item.id} className={styles.item}>
                        <ArrowsDown className={styles.arrows} />
                        <h4
                            className={styles.title}
                            dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <Button
                            className={["transparent-white"]}
                            to={item.url}
                        >
                            Bekijk vacature <ArrowRight />
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}

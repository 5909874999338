import React from "react"
import { useParams } from "react-router-dom"
import useFetch from "use-http"

import Layout from "../../components/layout"
import PageLoader from "../../components/page-loading"
import RecruiterDetail from "../../components/recruiter-detail"

export default function RecruiterSinglePage() {
    const { slug } = useParams()

    const { data, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/recruiters/find/${slug}`,
        {},
        [slug],
    )

    return loading ? (
        <PageLoader />
    ) : (
        <Layout seo={data.seo} yoast={false}>
            <RecruiterDetail recruiter={data} />
        </Layout>
    )
}

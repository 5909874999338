import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import request from "superagent"
import Layout from "../../components/layout"
import CaseHero from "../../components/case-hero"
import CaseDetail from "../../components/case-detail"
import Stories from "../../components/stories"
import PageLoader from "../../components/page-loading"

class CaseSingle extends Component {
    constructor(props) {
        super(props)

        this.state = {
            response: null,
            loading: true,
        }
    }

    componentDidMount() {
        this.getCase(this.props.match.params.slug)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.match.params.slug !== nextProps.match.params.slug) {
            this.getCase(nextProps.match.params.slug)
        }
    }

    getCase = (slug) => {
        this.setState({
            response: null,
            loading: true,
        })
        request
            .get(`${process.env.REACT_APP_API_URL}/cases/detail/${slug}`)
            .then((response) => {
                this.setState({
                    response: response.body,
                    loading: false,
                })
            })
            .catch(() => {
                this.setState({
                    response: false,
                    loading: true,
                })
            })
    }

    render() {
        const { response } = this.state

        return response === null ? (
            <PageLoader />
        ) : response === false ? (
            <Redirect to={`/facts-stories/`} />
        ) : (
            <Layout seo={response.yoast}>
                <CaseHero title={response.title} image={response.image} />
                <CaseDetail
                    content={response.content}
                    intro={response.intro}
                    quote={response.quote}
                />
                {response.stories.items && (
                    <Stories
                        title={response.stories.title}
                        button={
                            response.stories.button.text &&
                            response.stories.button
                        }
                        items={response.stories.items}
                    />
                )}
            </Layout>
        )
    }
}

export default CaseSingle

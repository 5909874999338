import { useRef } from "react"
import { Link } from "react-router-dom"
import styles from "./detail.module.scss"

import { classnames } from "../../helpers/classnames"
import FloatingButtons from "./floating-buttons"
import FunctionSection from "./sections/function"
import ClientSection from "./sections/client"
import SollicitatieWidget from "./sections/widget"
import ChecklistSection from "./sections/checklist"
import VacatureDetails from "./sections/details"
import VacatureRecruiter from "./sections/author"
import VacatureRelated from "./sections/related"

function VacatureDetail({ vacancy }) {
    const contentRef = useRef()
    const widgetRef = useRef()

    const handleMore = (event) => {
        event.preventDefault()

        window.scrollTo({
            top: contentRef.current.offsetTop - 100,
            left: 0,
            behavior: "smooth",
        })
    }

    const handleSolliciteren = (event) => {
        event.preventDefault()

        window.scrollTo({
            top: widgetRef.current.offsetTop - 70,
            left: 0,
            behavior: "smooth",
        })
    }

    return (
        <>
            <FloatingButtons toggle={handleSolliciteren} />
            <section className={styles.intro}>
                <div
                    className={styles.hero}
                    style={{
                        backgroundImage: `url("${vacancy.image}")`,
                    }}
                />
                <div className={classnames("wrapper", styles.wrapper)}>
                    <div className={styles.breadcrumbs}>
                        <Link to="/vacatures">Vacatures</Link>
                        <span
                            style={{
                                color: "#f6ac02",
                                margin: "0 0.25rem",
                            }}
                        >
                            /
                        </span>{" "}
                        <Link
                            to={`/vacatures/?branche=${vacancy.categorySlug}`}
                        >
                            {vacancy.category}
                        </Link>
                        <span
                            style={{
                                color: "#f6ac02",
                                margin: "0 0.25rem",
                            }}
                        >
                            /
                        </span>{" "}
                        {vacancy.title}
                    </div>

                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: vacancy.title }}
                    />
                    <div className={styles.summary}>
                        <div className={styles.left}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: vacancy.content.summary,
                                }}
                            />
                            <span className={styles.more} onClick={handleMore}>
                                Lees meer
                            </span>
                        </div>

                        <VacatureDetails details={vacancy.details} />
                    </div>
                </div>

                <div
                    ref={contentRef}
                    className={classnames("wrapper", styles.content)}
                >
                    <div>
                        {vacancy.content.function_description && (
                            <FunctionSection
                                description={
                                    vacancy.content.function_description
                                }
                            />
                        )}
                    </div>

                    <div className="flex flex-col" style={{ justifyContent: "space-between"}}>
                        {vacancy.content.requirements_description && (
                            <ChecklistSection
                                requirements={
                                    vacancy.content.requirements_description
                                }
                                offer={vacancy.content.offer_description}
                            />
                        )}
                        {vacancy.recruiter && (
                            <VacatureRecruiter recruiter={vacancy.recruiter} />
                        )}
                    </div>
                </div>
            </section>

            {vacancy.content.client_description && (
                <ClientSection
                    description={vacancy.content.client_description}
                    video={vacancy.video}
                />
            )}

            {vacancy.related.length > 1 && (
                <VacatureRelated related={vacancy.related} />
            )}

            {!vacancy.closed && (
                <div ref={widgetRef}>
                    <SollicitatieWidget vacancyId={vacancy.recruitNow.id} />
                </div>
            )}
        </>
    )
}

export default VacatureDetail

import React from "react"
import styles from "./styles.module.scss"

import Button from "../button"
import Mail from "../../icons/mail"
import Telephone from "../../icons/telephone"
import WhatsApp from "../../icons/whatsapp"
import ArrowRight from "../../icons/arrow-right"
import Location from "../../icons/location"

const Author = ({ recruiter }) => {
    return (
        <div className={styles.author}>
            <div className={styles.contact}>
                {recruiter.locatie && (
                    <span className={styles.detail}>
                        <Location />
                        {recruiter.locatie[0]}
                    </span>
                )}
                <span className={styles.detail}>
                    <a
                        href={`mailto:?subject=Recruiter van Career Rebels&body=${window.location.href}`}
                    >
                        <Mail />
                        {recruiter.author.emailaddress}
                    </a>
                </span>
                {recruiter.author.phonenumber && (
                    <span className={styles.detail}>
                        <a href={`tel:${recruiter.author.phonenumber}`}>
                            <Telephone />
                            {recruiter.author.phonenumber}
                        </a>
                    </span>
                )}
                {recruiter.author.whatsapp && (
                    <span className={styles.detail}>
                        <a href={`https://wa.me/${recruiter.author.whatsapp.replaceAll(' ', '')}`}>
                            <WhatsApp />
                            {recruiter.author.whatsapp.replace('316', '06-')}
                        </a>
                    </span>
                )}
                <Button
                    to="#"
                    modal="/modal-recruiter-aanmelden"
                    className={["blue", "space-between"]}
                    style={{ marginTop: "12px" }}
                    recruiter={recruiter}
                >
                    Ik kies voor {recruiter.name} <ArrowRight />
                </Button>
            </div>
            <figure className={styles.figure}>
                <img
                    src={recruiter.author.image}
                    srcSet={recruiter.author.srcset}
                    alt={recruiter.author.name}
                    className={styles.image}
                />
            </figure>
        </div>
    )
}

export default Author

import React, { Component } from "react"
import request from "superagent"
import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"

import styles from "./styles.module.scss"

import Loading from "../loading"
import InputContainer from "../input-container"
import Button from "../button"
import ArrowRight from "../../icons/arrow-right"
import ArrowLeft from "../../icons/arrow-left"
import Cross from "../../icons/cross"

class Wizard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            response: null,
            questionsResponse: null,
            questionsLoading: false,
            error: null,
            disableSubmit: false,
            step: 1,
            values: {},
            startValue: 3,
            sliderValue: 3,
        }
    }

    getQuestions = () => {
        this.setState({
            questionsResponse: null,
            questionsLoading: true,
        })
        request
            .get(`${process.env.REACT_APP_API_URL}/wizard/questions`)
            .then((response) => {
                this.setState({
                    questionsResponse: response.body,
                    questionsLoading: false,
                    step: 2,
                })
            })
            .catch(() => {
                this.setState({
                    questionsResponse: false,
                    questionsLoading: false,
                })
            })
    }

    submit = (event) => {
        event.preventDefault()
        let countErrors = 0
        let emptyFields = 0

        if (this.state.step - 1 === this.props.details.total) {
            Object.keys(this.state.values).forEach((item) => {
                let field = this.state.values[item]

                if (field.value === null && field.required === true) {
                    emptyFields++
                }

                if (field.error === true) {
                    countErrors++
                }
            })

            if (countErrors === 0 && emptyFields === 0) {
                this.setState({
                    error: null,
                })
                this.setState({
                    loading: true,
                    error: null,
                })

                request
                    .post(`${process.env.REACT_APP_API_URL}/wizard/signup/`)
                    .send(this.state.values)
                    .then((response) => {
                        this.setState({
                            response: {
                                title: response.body.message,
                                description: response.body.data.description,
                                button: response.body.data.button,
                            },
                            loading: false,
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            response: null,
                            loading: false,
                        })
                        if (error.response) {
                            this.setState({
                                error: error.response.body.message,
                            })
                        } else {
                            this.setState({
                                error: "Er ging iets mis...",
                            })
                        }
                    })
            } else {
                this.setState({
                    error: "Sommige velden kloppen niet!",
                })
            }
        } else {
            this.setState({
                step: this.state.step + 1,
                startValue: 3,
            })
            this.scrollToForm()
        }
    }

    scrollToForm = () => {
        let anchor = document.querySelector("#form")
        anchor.scrollIntoView({
            behavior: "smooth",
            block: "start",
        })
    }

    back = () => {
        this.setState({
            step: this.state.step - 1,
        })
        this.scrollToForm()
    }

    saveValue = (field, value, error, required) => {
        this.setState({
            values: Object.assign(this.state.values, {
                [field]: { value: value, error: error, required: required },
            }),
        })
    }

    update = (render, handle, value) => {
        this.saveValue(`question_${this.state.step - 1}`, value[0], false, true)
        this.setState({
            sliderValue: value[0],
        })
    }

    render() {
        const { details } = this.props
        const {
            loading,
            response,
            error,
            step,
            values,
            questionsResponse,
            questionsLoading,
            sliderValue,
        } = this.state

        return (
            <div className={styles.modal}>
                <figure className={styles.image}>
                    {step === 1 && <img src={details.intro.image} alt="" />}
                    {questionsResponse && step > 1 && step <= details.total && (
                        <img src={questionsResponse[step - 2].image} alt="" />
                    )}
                    {step - 1 === details.total && (
                        <img src={details.intro.image} alt="" />
                    )}
                </figure>
                <div className={styles.gradient} />
                <form className={styles.form} onSubmit={this.submit} id="form">
                    {response ? (
                        <article
                            className={[
                                styles.article,
                                styles["article--success"],
                            ].join(" ")}
                        >
                            <h2
                                className={styles.title}
                                dangerouslySetInnerHTML={{
                                    __html: response.title,
                                }}
                            />
                            <p
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: response.description,
                                }}
                            />
                            {response.button.text && (
                                <Button
                                    className={["blue"]}
                                    to={response.button.link}
                                >
                                    {response.button.text}
                                    <ArrowRight />
                                </Button>
                            )}
                        </article>
                    ) : (
                        <>
                            {step > 1 && (
                                <>
                                    <div className={styles.steps}>
                                        <span
                                            style={{
                                                width: `${
                                                    ((step - 1) /
                                                        details.total) *
                                                    100
                                                }%`,
                                            }}
                                        />
                                    </div>
                                    <span className={styles.count}>
                                        {step - 1} / {details.total}
                                    </span>
                                </>
                            )}
                            {step === 1 ? (
                                <article
                                    className={[
                                        styles.article,
                                        styles["article--intro"],
                                    ].join(" ")}
                                >
                                    <h2
                                        className={styles.title}
                                        dangerouslySetInnerHTML={{
                                            __html: details.intro.title,
                                        }}
                                    />
                                    <p
                                        className={styles.description}
                                        dangerouslySetInnerHTML={{
                                            __html: details.intro.description,
                                        }}
                                    />
                                </article>
                            ) : step > 1 && step <= details.total ? (
                                questionsResponse && (
                                    <>
                                        <article className={styles.article}>
                                            <h2
                                                className={styles.title}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        questionsResponse[
                                                            step - 2
                                                        ].title,
                                                }}
                                            />
                                        </article>
                                        <div
                                            className={[
                                                styles.slider,
                                                sliderValue === 3
                                                    ? styles["slider--last"]
                                                    : null,
                                                sliderValue === 0
                                                    ? styles["slider--first"]
                                                    : null,
                                            ].join(" ")}
                                        >
                                            <Nouislider
                                                range={{ min: 0, max: 3 }}
                                                start={
                                                    this.state.values[
                                                        `question_${step - 1}`
                                                    ]
                                                        ? this.state.values[
                                                              `question_${
                                                                  step - 1
                                                              }`
                                                          ].value
                                                        : 3
                                                }
                                                step={1}
                                                connect
                                                tooltips={{
                                                    to(value) {
                                                        return questionsResponse[
                                                            step - 2
                                                        ].labels.steps[value]
                                                    },
                                                }}
                                                onUpdate={this.update}
                                                onSlide={this.slide}
                                            />
                                            <span
                                                className={
                                                    styles["slider-steps"]
                                                }
                                            >
                                                <span />
                                                <span />
                                                <span />
                                                <span />
                                            </span>
                                        </div>

                                        <div className={styles.labels}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        questionsResponse[
                                                            step - 2
                                                        ].labels.left,
                                                }}
                                            />
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        questionsResponse[
                                                            step - 2
                                                        ].labels.right,
                                                }}
                                            />
                                        </div>
                                    </>
                                )
                            ) : step - 1 === details.total ? (
                                <div className={styles.final}>
                                    <article className={styles.article}>
                                        <h2
                                            className={styles.title}
                                            dangerouslySetInnerHTML={{
                                                __html: details.final.title,
                                            }}
                                        />
                                        <p
                                            className={styles.description}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    details.final.description,
                                            }}
                                        />
                                    </article>
                                    <div className={styles.fields}>
                                        <InputContainer
                                            label="Voornaam"
                                            name="firstName"
                                            save={this.saveValue}
                                            val={
                                                values
                                                    ? values["firstName"]
                                                    : ""
                                            }
                                            required={true}
                                            variants={["half"]}
                                        />
                                        <InputContainer
                                            label="Achternaam"
                                            name="lastName"
                                            save={this.saveValue}
                                            val={
                                                values ? values["lastName"] : ""
                                            }
                                            required={true}
                                            variants={["half"]}
                                        />
                                        <InputContainer
                                            label="E-mailadres"
                                            name="emailaddress"
                                            save={this.saveValue}
                                            val={
                                                values
                                                    ? values["emailaddress"]
                                                    : ""
                                            }
                                            regex={
                                                /^(([^<>(),;:\s@"]+([^<>(),;:\s@"]+)*)|(.+))@(([^<>()[.,;:\s@"]+)+[^<>()[,;:\s@]{2,})$/i
                                            }
                                            required={true}
                                            variants={["half"]}
                                        />
                                        <InputContainer
                                            label="Telefoonnummer"
                                            name="phonenumber"
                                            save={this.saveValue}
                                            val={
                                                values
                                                    ? values["phonenumber"]
                                                    : ""
                                            }
                                            regex={
                                                /^((\+|00(\s|\s?\s?)?)31(\s|\s?\s?)?(\(0\)[\s]?)?|0)[1-9]((\s|\s?\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
                                            }
                                            required={true}
                                            variants={["half"]}
                                        />
                                        <InputContainer
                                            label="Woonplaats"
                                            name="city"
                                            save={this.saveValue}
                                            val={values ? values["city"] : ""}
                                            required={true}
                                            variants={["full"]}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <div
                                className={[
                                    styles["button-container"],
                                    step - 1 === details.total
                                        ? styles["button-container--final"]
                                        : "",
                                ].join(" ")}
                            >
                                {step > 1 && (
                                    <span
                                        className={styles.back}
                                        onClick={this.back}
                                    >
                                        <ArrowLeft />
                                        Vorige
                                    </span>
                                )}
                                <Button
                                    className={[
                                        "blue",
                                        loading || questionsLoading === true
                                            ? "loading"
                                            : "",
                                    ]}
                                    type="submit"
                                    onClick={step === 1 && this.getQuestions}
                                >
                                    {loading || questionsLoading ? (
                                        <Loading color="white" />
                                    ) : (
                                        <>
                                            {step === 1 &&
                                                details.intro.button.text}
                                            {step > 1 &&
                                                step <= details.total &&
                                                questionsResponse[step - 2]
                                                    .button}
                                            {step - 1 === details.total &&
                                                details.final.button}
                                            <ArrowRight />
                                        </>
                                    )}
                                </Button>
                            </div>
                            {error && (
                                <div className={styles.error}>
                                    <Cross />
                                    {error}
                                </div>
                            )}
                        </>
                    )}
                </form>
            </div>
        )
    }
}

export default Wizard

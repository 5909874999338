import React, { Component } from "react"
import { Link } from "react-router-dom"

import styles from "./styles.module.scss"
import ArrowLeft from "../../icons/arrow-left"
import ArrowRight from "../../icons/arrow-right"
import Telephone from "../../icons/telephone"
import Mail from "../../icons/mail"
import LinkedIn from "../../icons/linkedin"
import Whatsapp from "../../icons/whatsapp"
import ArrowRightSmall from "../../icons/arrow-right-small"

class TeamItem extends Component {
    openSlide = (index) => {
        this.props.updateActive(index)
    }

    closeSlide = (index) => {
        this.props.closeActiveSlide(index)
    }

    render() {
        const { item, index, activeIndex } = this.props

        return (
            <div
                className={[
                    styles.item,
                    activeIndex === index ? styles["item--show-info"] : null,
                ].join(" ")}
            >
                <div
                    className={styles.person}
                    onClick={(e) =>
                        activeIndex === index
                            ? this.closeSlide(index)
                            : this.openSlide(index)
                    }
                >
                    <img
                        className={styles.image}
                        src={item.image}
                        alt={item.name}
                    />
                    {item.hover && (
                        <img
                            className={styles.imageHover}
                            src={item.hover}
                            alt={item.name}
                        />
                    )}
                    <span
                        className={styles.name}
                        dangerouslySetInnerHTML={{ __html: item.name }}
                    />
                    <span
                        className={styles.position}
                        dangerouslySetInnerHTML={{ __html: item.position }}
                    />
                </div>
                <div className={styles.information}>
                    <h5
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: item.content.title }}
                    />
                    <p
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: item.content.text }}
                    />

                    {item.category_id === 184 && (
                        <Link to="/onze-diensten/#flex" className={styles.more}>
                            <ArrowRightSmall /> Meer weten over flex work?
                        </Link>
                    )}

                    {item.category_id === 185 && (
                        <Link
                            to="/onze-diensten/#recruitment"
                            className={styles.more}
                        >
                            <ArrowRightSmall /> Meer weten over recruitment?
                        </Link>
                    )}

                    {item.category_id === 189 && (
                        <Link to="/onze-diensten/#hr" className={styles.more}>
                            <ArrowRightSmall /> Meer weten over HR on demand?
                        </Link>
                    )}

                    {item.category_id === 193 && (
                        <Link
                            to="/werkgevers/#education"
                            className={styles.more}
                        >
                            <ArrowRightSmall /> Meer weten over Education?
                        </Link>
                    )}

                    <ul className={styles.contact}>
                        {item.contact.twitter && (
                            <li>
                                <a
                                    href={item.contact.twitter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <LinkedIn />
                                    LinkedIn
                                </a>
                            </li>
                        )}
                        {item.contact.emailaddress && (
                            <li>
                                <a href={`mailto:${item.contact.emailaddress}`}>
                                    <Mail />
                                    {item.contact.emailaddress}
                                </a>
                            </li>
                        )}
                        {item.contact.phonenumber && (
                            <li>
                                <a href={`tel:${item.contact.phonenumber.replaceAll(' ', '')}`}>
                                    <Telephone />
                                    {item.contact.phonenumber}
                                </a>
                            </li>
                        )}
                        {item.contact.whatsapp && (
                            <li>
                                <a href={`https://wa.me/${item.contact.whatsapp.replaceAll(' ', '')}`}>
                                    <Whatsapp />
                                    {item.contact.whatsapp.replace('316', '06-')}
                                </a>
                            </li>
                        )}
                        {item.pagina && (
                            <li>
                                <a href={item.pagina}>
                                    <ArrowRight />
                                    Kies mij als recruiter!
                                </a>
                            </li>
                        )}
                    </ul>
                    <span
                        onClick={(e) => this.closeSlide(index)}
                        className={styles.close}
                    >
                        <ArrowLeft /> Sluiten
                    </span>
                </div>
            </div>
        )
    }
}

export default TeamItem

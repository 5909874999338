import { Helmet } from "react-helmet"

const Yoast = ({ yoast }) => {
    let indexable = ""

    if (process.env.REACT_APP_ENV === "development") {
        indexable = "noindex,nofollow"
    } else {
        indexable = `${yoast.robots.index},${yoast.robots.follow}`
    }

    return (
        <Helmet title={yoast.title} defer={false}>
            <meta name="description" content={yoast.description} />

            <meta name="robots" content={indexable} />
            <meta property="og:locale" content={yoast.og_locale} />
            <meta property="og:type" content={yoast.og_type} />
            <meta property="og:title" content={yoast.og_title} />
            <meta property="og:description" content={yoast.og_description} />
            <meta property="og:url" content={yoast.og_url} />
            <meta property="og:site_name" content={yoast.og_site_name} />
            <meta property="og:image" content={yoast.og_image.url} />
            <meta property="og:image:url" content={yoast.og_image.url} />
            <meta property="og:image:width" content={yoast.og_image.width} />
            <meta property="og:image:height" content={yoast.og_image.height} />
            <meta property="og:image:type" content={yoast.og_image.type} />

            <meta property="twitter:card" content={yoast.twitter_card} />
            <meta property="twitter:title" content={yoast.og_title} />
            <meta
                property="twitter:description"
                content={yoast.og_description}
            />
            <meta property="twitter:image" content={yoast.og_image.url} />

            <script type="application/ld+json">
                {JSON.stringify(yoast.schema)}
            </script>
        </Helmet>
    )
}

export default Yoast

import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"

import ArrowUp from "../../icons/arrow-up"

const ToTop = () => {
    const [active, setActive] = useState(false)

    const scrollTop = () => {
        setActive(!active)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
    }

    const listener = () => {
        if (window.scrollY > 500 && !active) {
            setActive(true)
        }

        if (window.scrollY <= 500 && active) {
            setActive(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", listener, {passive: true})

        return () => {
            window.removeEventListener("scroll", listener)
        }
    })

    if (!active) return <></>

    return (
        <div className={styles.button} onClick={scrollTop}>
            <ArrowUp />
        </div>
    )
}

export default ToTop

import React from "react"
import styles from "./styles.module.scss"

import Button from "../../button"
import ArrowRight from "../../../icons/arrow-right"
import ArrowsRight from "../../../icons/arrows-right"

const Hero = ({ detail }) => {
    return (
        <section className={styles.hero}>
            <ArrowsRight className={styles.arrows} />
            <div className={["wrapper", styles.wrapper].join(" ")}>
                <div className={styles.content}>
                    <h2
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: detail.title }}
                    />
                    {detail.description && (
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: detail.description,
                            }}
                        />
                    )}
                    {detail.button && (
                        <Button to={detail.button.link} className={["yellow"]}>
                            {detail.button.text}
                            <ArrowRight />
                        </Button>
                    )}
                </div>
                <div className={styles.image}>
                    {detail.image && (
                        <img
                            src={detail.image}
                            srcSet={detail.srcset || ""}
                            alt=""
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

export default Hero

import React, { Component } from "react"
import { Link } from "react-router-dom"

import styles from "./styles.module.scss"
import ArrowRight from "../../icons/arrow-right"

class StoriesGridItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: null,
        }
    }

    calculateHeight = () => {
        this.setState({
            height: this.refs.content.clientHeight - 17,
        })
    }

    componentDidMount() {
        setTimeout(() => {
            this.calculateHeight()
        }, 1)
        window.addEventListener("resize", this.calculateHeight)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.calculateHeight, false)
    }

    render() {
        const { item } = this.props

        const slug = item.slug.endsWith('/') ? item.slug : item.slug + "/"

        return (
            <Link to={slug} className={styles.item}>
                <figure className={styles.image}>
                    <div
                        className={styles.background}
                        style={{
                            backgroundImage: `url(${item.image})`,
                        }}
                    />
                </figure>
                <div className={styles.content} ref="content">
                    <span
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <span
                        className={styles.subtitle}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <span className={styles.more}>
                        Bekijk story <ArrowRight />
                    </span>
                </div>
            </Link>
        )
    }
}

export default StoriesGridItem

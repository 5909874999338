import React from "react"
import { Switch, Route, Redirect, Link } from "react-router-dom"
import LinkedInTag from "react-linkedin-insight"

import Header from "../header"
import Footer from "../footer"
import GoogleTagManager from "../tag-manager"
import CookieNotice from "../cookie-notice"

import Home from "../../pages/home"
import NotFound from "../../pages/not-found"
import Cases from "../../pages/cases"
import CaseSingle from "../../pages/cases/single"
import ApplyNow from "../../pages/apply-now"
import Employers from "../../pages/employers"
import Contact from "../../pages/contact"
import About from "../../pages/about"
import Hours from "../../pages/hours"
import WizardPage from "../../pages/wizard"
import RecruitersPage from "../../pages/recruiters"
import RecruiterSingle from "../../pages/recruiters/single"
import Kleurentest from "../../pages/kleurentest"
import DefaultPage from "../../pages/default"
import VacaturesPage from "../../pages/vacatures"
import VacaturesSinglePage from "../../pages/vacatures/single"
import IndeedPage from "../../pages/indeed"

export default function Routes() {
    const snap = navigator.userAgent === "ReactSnap"

    if (snap === false && process.env.NODE_ENV === "production") {
        LinkedInTag.init("4032177", "dc", false)
    }


    return (
        <div className="cr">
            {snap === false && process.env.NODE_ENV === "production" && (
                <GoogleTagManager gtmId="GTM-59GZMMM" />
            )}
            <Header />
            <CookieNotice />
            <main>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/home">
                        <Redirect to="/" />
                    </Route>
                    <Route exact path="/vacatures/" component={VacaturesPage} />
                    <Route
                        exact
                        path="/vacatures/pagina/:page"
                        component={VacaturesPage}
                    />
                    <Route
                        exact
                        path="/vacatures/:slug/"
                        component={VacaturesSinglePage}
                    />
                    <Route
                        exact
                        path="/indeed/:slug"
                        component={IndeedPage}
                    />
                    {/*<Route*/}
                    {/*    exact*/}
                    {/*    path="/vacatures/:category/:slug/"*/}
                    {/*    component={VacaturesSinglePage}*/}
                    {/*/>*/}
                    <Route
                        exact
                        path="/facts-stories/"
                        render={(props) => <Cases {...props} />}
                    />
                    <Route
                        exact
                        path="/facts-stories/pagina/:page/"
                        render={(props) => <Cases {...props} />}
                    />
                    <Route
                        exact
                        path="/facts-stories/:slug/"
                        render={(props) => <CaseSingle {...props} />}
                    />
                    <Route
                        exact
                        path="/recruiters/"
                        component={RecruitersPage}
                    />
                    <Route
                        exact
                        path="/recruiters/:slug/"
                        component={RecruiterSingle}
                    />
                    <Route
                        exact
                        path="/over-career-rebels/"
                        component={About}
                    />
                    <Route
                        exact
                        path="/klantenservice/"
                        render={() => <Redirect to="/contact/" />}
                    />
                    <Route exact path="/contact/" component={Contact} />
                    <Route exact path="/inschrijven/" component={ApplyNow} />
                    <Route exact path="/werkgevers/" component={Employers} />
                    <Route exact path="/onze-diensten2/" component={Employers} />
                    <Route exact path="/mijn-uren/" component={Hours} />
                    <Route
                        exact
                        path="/beroepskeuzetest"
                        component={WizardPage}
                    />
                    <Route exact path="/kleurentest/" component={Kleurentest} />
                    <Route path="/niet-gevonden/" component={NotFound} />
                    <Route exact path="/:page" component={DefaultPage} />
                    <Route render={() => <Redirect to={`/niet-gevonden/`} />} />
                </Switch>
                {snap && (
                    <>
                        <Link to="/facts-stories/" style={{ display: "none" }}>
                            Facts & Stories
                        </Link>
                        <Link
                            to="/beroepskeuzetest/"
                            style={{ display: "none" }}
                        >
                            Beroepskeuzetest
                        </Link>
                    </>
                )}
            </main>
            <Footer />
        </div>
    )
}

import React, { Component } from "react"
import { Swiper } from "swiper"
import request from "superagent"

import styles from "./styles.module.scss"
import itemStyles from "../team-item/styles.module.scss"

import TeamItem from "../team-item"
import Loading from "../loading"
import ArrowRight from "../../icons/arrow-right"
import ArrowLeft from "../../icons/arrow-left"

class Team extends Component {
    constructor(props) {
        super(props)
        this.state = {
            swiper: null,
            activeIndex: null,
            items: null,
            loading: true,
            itemId: 0,
            isBeginning: true,
            isEnd: false,
            translate: 0,
            isMobile: window.innerWidth < 768,
        }
    }

    componentDidMount() {
        this.getItems(this.props.categories[0].id)
    }

    setActiveIndex = (index) => {
        this.setState({
            activeIndex: index,
        })
        setTimeout(() => {
            this.state.swiper.update()
            this.state.swiper.slideTo(index, 400)
            if (window.innerWidth < 768) {
                this.state.swiper.setTranslate(this.state.translate - 310 - 24)
            }
        }, 400)
    }

    closeActiveSlide = (index) => {
        this.setState({
            activeIndex: null,
        })
        this.state.swiper.update()
        if (window.innerWidth < 768) {
            this.state.swiper.slideTo(index, 400)
        } else {
            this.state.swiper.slideTo(index, 400)
        }
    }

    getItems = (id) => {
        const self = this
        this.setState({
            items: null,
            loading: true,
            itemId: id,
        })
        if (this.state.swiper) {
            this.state.swiper.destroy(true, true)
        }

        request
            .get(`${process.env.REACT_APP_API_URL}/paginas/about/category`)
            .query({ id: id })
            .then((response) => {
                this.setState({
                    items: response.body,
                    loading: false,
                    activeIndex: null,
                })
                let swiper = new Swiper(`.${styles.slider}`, {
                    slidesPerView: "auto",
                    containerModifierClass: "",
                    wrapperClass: styles["slide-items"],
                    slideClass: itemStyles.item,
                    slideActiveClass: itemStyles["item--active"],
                    // mousewheel: true,
                    speed: 400,
                    freeMode: true,
                    freeModeMomentumRatio: 0.4,
                    freeModeMomentumBounce: false,
                    on: {
                        transitionStart: function () {
                            self.setState({
                                translate: this.translate,
                            })
                        },
                        slideChange: function () {
                            self.setState({
                                isBeginning: false,
                            })
                            if (this.isEnd) {
                                self.setState({
                                    isEnd: true,
                                })
                            } else {
                                self.setState({
                                    isEnd: false,
                                })
                            }
                            if (this.isBeginning) {
                                self.setState({
                                    isBeginning: true,
                                    isEnd: false,
                                })
                            } else {
                                self.setState({
                                    isBeginning: false,
                                })
                            }
                        },
                        sliderMove: function () {
                            this.update()
                        },
                    },
                })
                this.setState({
                    swiper: swiper,
                    isBeginning: true,
                    isEnd: false,
                    translate: 0,
                })
                // swiper.update();
                swiper.slideTo(0, 400)
            })
            .catch(() => {
                this.setState({
                    items: null,
                    loading: false,
                })
            })
    }

    slide = (event, direction) => {
        event.preventDefault()
        if (direction === "next") {
            this.state.swiper.slideNext(400)
            this.setState({
                isBeginning: false,
            })
        } else {
            this.state.swiper.slidePrev(400)
            this.setState({
                isEnd: false,
            })
        }
        if (this.state.swiper.isBeginning) {
            this.setState({
                isBeginning: true,
            })
        }
        if (this.state.swiper.isEnd) {
            this.setState({
                isEnd: true,
            })
        }
    }

    render() {
        const { title, categories } = this.props

        const {
            swiper,
            activeIndex,
            items,
            itemId,
            isBeginning,
            isEnd,
            isMobile,
        } = this.state

        return (
            <section className={styles.team}>
                <div className="wrapper">
                    <header className={styles.header} ref="header">
                        <div className={styles.heading}>
                            <h2
                                className={styles.title}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        </div>
                        {!isMobile && (
                            <>
                                {items && items.length > 4 && (
                                    <div className={styles.navigation}>
                                        <a
                                            href="#!"
                                            onClick={(e) =>
                                                this.slide(e, "previous")
                                            }
                                            className={[
                                                styles.more,
                                                styles["more--prev"],
                                                isBeginning
                                                    ? styles["more--disabled"]
                                                    : "",
                                            ].join(" ")}
                                        >
                                            <ArrowLeft />
                                        </a>
                                        <a
                                            href="#!"
                                            onClick={(e) =>
                                                this.slide(e, "next")
                                            }
                                            className={[
                                                styles.more,
                                                isEnd
                                                    ? styles["more--disabled"]
                                                    : "",
                                            ].join(" ")}
                                        >
                                            <ArrowRight />
                                        </a>
                                    </div>
                                )}
                            </>
                        )}
                    </header>
                    <div className={styles.categories}>
                        {categories &&
                            categories.map((category, i) => {
                                return category.items ? (
                                    <span
                                        key={i}
                                        className={
                                            category.id === itemId
                                                ? styles["is-active"]
                                                : ""
                                        }
                                        onClick={() => this.getItems(category.id)}
                                        dangerouslySetInnerHTML={{
                                            __html: category.label,
                                        }}
                                    />
                                ) : null
                            })}
                    </div>
                    <div className={styles.slider}>
                        <div className={styles["slide-items"]}>
                            {items ? (
                                items.map((item, i) => (
                                    <TeamItem
                                        swiper={swiper}
                                        item={item}
                                        index={i}
                                        key={i}
                                        activeIndex={activeIndex}
                                        updateActive={this.setActiveIndex}
                                        closeActiveSlide={this.closeActiveSlide}
                                    />
                                ))
                            ) : (
                                <div className={styles.loading}>
                                    <Loading color="white" />
                                </div>
                            )}
                        </div>
                    </div>
                    {isMobile && (
                        <>
                            {items && items.length > 4 && (
                                <div className={styles.navigation}>
                                    <a
                                        href="#!"
                                        onClick={(e) =>
                                            this.slide(e, "previous")
                                        }
                                        className={[
                                            styles.more,
                                            styles["more--prev"],
                                            isBeginning
                                                ? styles["more--disabled"]
                                                : "",
                                        ].join(" ")}
                                    >
                                        <ArrowLeft />
                                    </a>
                                    <a
                                        href="#!"
                                        onClick={(e) => this.slide(e, "next")}
                                        className={[
                                            styles.more,
                                            isEnd
                                                ? styles["more--disabled"]
                                                : "",
                                        ].join(" ")}
                                    >
                                        <ArrowRight />
                                    </a>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>
        )
    }
}

export default Team

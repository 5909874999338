import React from "react"
import styles from "./styles.module.scss"
import { Swiper, SwiperSlide } from "swiper/react"

const Testimonial = ({ title, description, items }) => {
    return (
        <div className="flex-col">
            <div>
                <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
                <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <div>
                <Swiper
                    cssMode={true}
                    spaceBetween={24}
                    slidesPerView={1}
                    navigation={true}
                    speed={2000}
                    autoplay={{
                        delay: 5000,
                        stopOnLastSlide: false,
                    }}
                    loop={true}
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                        },
                    }}
                >
                    {items &&
                        items.map((item, i) => (
                            <SwiperSlide key={i} className={styles.item}>
                                <div className={styles.quote}>
                                    <blockquote dangerouslySetInnerHTML={{ __html: item.quote }} />
                                    <div className={styles.author}>
                                        <figure>
                                            <img src={item.photo} alt="" />
                                        </figure>
                                        <span><strong>{item.name}</strong>, {item.functie}</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Testimonial

import React from "react"
import styles from "./styles.module.scss"

const Timeline = ({ title, description, items }) => {
    return (
        <>
            <div>
                <h2
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
            <div className={styles.timeline}>
                {items.map((item, i) => (
                    <div key={i} className={styles.item}>
                        <div className={styles.tooltip}>
                            <div dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                        <div className={styles.label}>
                            <span data-step={i + 1}>
                                {item.title.replace(/(<([^>]+)>)/gi, "")}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Timeline

import React from "react"
import styles from "./styles.module.scss"

import ArrowRight from "../../icons/arrow-right"
import Button from "../../components/button"

const ImageCta = ({ detail, variants }) => {
    const classnames = () => {
        let result = []
        result.push(styles.cta)

        if (variants) {
            variants.forEach(function (variant) {
                result.push(styles[`cta--${variant}`])
            })
        }
        return result.join(" ")
    }

    return (
        <section className={classnames(variants)}>
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                <span className={styles.gradient} />
                <div className={styles.content}>
                    <h2
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: detail.title }}
                    />
                    <p
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: detail.description }}
                    />
                    {detail.button && (
                        <Button
                            to={detail.button.link}
                            className={["blue", "space-between"]}
                        >
                            {detail.button.text}
                            <ArrowRight />
                        </Button>
                    )}
                </div>
                {detail.video ? (
                    <div className={styles.video}>
                        {/*<div className="vdideo">*/}
                            <video src={detail.video} controls={true} />
                        {/*</div>*/}
                    </div>
                ) : (
                    <img
                        src={detail.image}
                        srcSet={detail.srcset}
                        alt={detail.title}
                        className={styles.image}
                    />
                )}
            </div>
        </section>
    )
}

export default ImageCta
